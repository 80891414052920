* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}
/* @media print {
  #root {
    width: 100%;
    height: 100% !important;
    overflow: visible;
  }
} */
