@font-face {
  font-family: "Poppy Quiet Sans";
  src: url("./fonts/QuietSans/QuietSans-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppy Quiet Sans";
  src: url("./fonts/QuietSans/QuietSans-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppy Quiet Sans";
  src: url("./fonts/QuietSans/QuietSans-ExtraLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppy Quiet Sans";
  src: url("./fonts/QuietSans/QuietSans-SemiBold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppy Quiet Sans";
  src: url("./fonts/QuietSans/QuietSans-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppy Quiet Sans";
  src: url("./fonts/QuietSans/QuietSans-ExtraBold.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
